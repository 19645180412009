export const loadGoogleMapsApi = (): Promise<void> => {
  return new Promise((resolve, reject) => {
    if ((window as any)?.google) {
      resolve(); // Google Maps API is already loaded
      return;
    }

    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${import.meta.env.VITE_GOOGLE_PLACE_NAME_API}&libraries=places`;
    script.async = true;
    script.defer = true;

    script.onload = () => {
      console.log('Google Maps API loaded.')
      resolve(); // Resolve the promise once the script is loaded
    };

    script.onerror = () => {
      reject(new Error('Failed to load Google Maps API'));
    };

    document.head.appendChild(script);
  });
};