/* eslint-disable import/order */
import '@/@iconify/icons-bundle'
import App from '@/App.vue'
import ability from '@/plugins/casl/ability'

import { useAppAbility } from '@/plugins/casl/useAppAbility'
import i18n from '@/plugins/i18n'
import layoutsPlugin from '@/plugins/layouts'
import vuetify from '@/plugins/vuetify'
import { loadFonts } from '@/plugins/webfontloader'
import router from '@/router'
import { abilitiesPlugin } from '@casl/vue'
import '@core/scss/template/index.scss'
import * as Sentry from '@sentry/vue'
import '@styles/styles.scss'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import VueViewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import { createApp, markRaw } from 'vue'
import { SENTRY_SPAN_ERROR_MSG } from './constants'
import { loadGoogleMapsApi } from './googlePlaceApi'

loadFonts()

// Create vue app
const app = createApp(App)

const pinia = createPinia()

pinia.use(piniaPluginPersistedstate)

pinia.use(({ store }) => {
  store.$router = markRaw(router)

  store.$ability = useAppAbility()
})

// Use plugins
app.use(vuetify)
app.use(pinia)
app.use(router)
app.use(layoutsPlugin)
app.use(i18n)
app.use(VueViewer)
app.use(abilitiesPlugin, ability, {
  useGlobalProperties: true,
})

Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
  enabled: import.meta.env.VITE_IS_SENTRY_SOURCE_MAP_ENABLED === 'true',

  // Monitor the rendering performance of your application and its components.
  trackComponents: true,
  hooks: ['activate', 'create', 'unmount', 'mount', 'update'],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.1, // 10% of transactions will be sampled

  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: [import.meta.env.VITE_APP_URL, import.meta.env.VITE_API_BASE_URL, /^\//],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 0.1,
  logErrors: false,
  beforeSend(event, hint) {
    const error: any = hint.originalException

    const shouldNotReportError = error?.message?.includes(SENTRY_SPAN_ERROR_MSG) || error?.message?.includes('trap returned falsish for property') || error?.message?.includes('$_sentrySpans')
    if (shouldNotReportError)
      return null

    // Continue sending to Sentry
    return event
  },

})

loadGoogleMapsApi()
  .then(() => {
    console.log('Google Maps API loaded successfully');
  })
  .catch((error) => {
    console.error('Failed to load Google Maps API:', error);
    
    // Optional: Show an error message or handle the failure
    document.getElementById('app')!.innerHTML = '<h1>Failed to load Google Maps API</h1>';
  })
  .finally(() => {
    // This will run regardless of success or failure
    app.mount('#app');
  });
