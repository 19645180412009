import i18n from '@/plugins/i18n'

export const reportClusterHeader = [i18n.global.t('Cluster Name'), i18n.global.t('Pending'), i18n.global.t('In Process'), i18n.global.t('Available'), i18n.global.t('Expiring'), i18n.global.t('Expired'), i18n.global.t('Cancelled'), i18n.global.t('Confirmed'), i18n.global.t('Completed')]

export const reportRouteHeader = [i18n.global.t('Route'), i18n.global.t('Pending'), i18n.global.t('In Process'), i18n.global.t('Available'), i18n.global.t('Expiring'), i18n.global.t('Expired'), i18n.global.t('Cancelled'), i18n.global.t('Confirmed'), i18n.global.t('Completed')]

export const reportHeaderValue = ['pending', 'in_process', 'available', 'expiring', 'expired', 'cancelled', 'confirmed', 'completed']

export const reportSmsCampaign = [
  { title: 'Campaign ID', key: 'campaignId' },
  { title: 'Date & Time', key: 'createdAt' },
  { title: 'Total Count', key: 'messagesSent' },
  { title: 'Campaign Status', key: 'status' },
  { title: 'Actions', key: 'actions', sortable: false, align: 'center' },
]

export const reportWaCampaign = [
  { title: 'Campaign ID', key: 'campaignId' },
  { title: 'Date & Time', key: 'createdAt' },
  { title: 'Total Count', key: 'messagesSent' },
  { title: 'Actions', key: 'actions', sortable: false, align: 'center' },
]

export const checkSmsSent = [
  { text: i18n.global.t('Success'), value: 'success' },
  { text: i18n.global.t('Failed'), value: 'failed' },
  { text: i18n.global.t('Pending'), value: 'pending' },
  { text: i18n.global.t('ALL'), value: '' },
]

export const reportExportOptions = [
  { text: i18n.global.t('Excel'), value: 'excel' },
  { text: i18n.global.t('PDF'), value: 'pdf' },
]

export const campaignColumns = [
  { name: 'Campaign ID', value: 'campaignId' },
  { name: 'Date & Time', value: 'createdAt' },
  { name: 'Campaign Status', value: 'status' },
]

export const waCampaignColumns = [
  { name: 'Campaign ID', value: 'campaignId' },
  { name: 'Date & Time', value: 'createdAt' },
]

export const searchFields = reactive({
  fromDate: null,
  toDate: null,
  campaignId: '',
  status: '',
})
