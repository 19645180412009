import { reactive } from 'vue'
import { reset, setFieldError } from '@/helpers'

const errorStoreState = reactive({
  phoneError: '',
  emailError: '',
  nameError: '',
  placeError: '',
  displayNameError: '',
  codeError: '',
  mobileError: '',
  truckNoError: '',
  driverNoError: '',
  bannerImage: '',
  truckNo: '',
  loadingDateError: '',
})

const resetData = reactive({ ...errorStoreState })

const errorStoreActions = {
  clearErrors() {
    reset(errorStoreState, resetData)
  },

  clearFieldError(field: string) {
    errorStoreState[field] = null
  },

  handleApiError(error: object) {
    errorStoreActions.clearErrors() // Clear errors before handling new errors

    const errorFields = Object.keys(error)

    if (errorFields.length > 0) {
      const firstErrorField = errorFields[0]

      const errorMessage = error[firstErrorField].find(Boolean)

      // Set the corresponding error field in the store
      setFieldError(errorStoreState, firstErrorField, errorMessage)

      return false
    }
  },
}

export const useErrorStore = () => ({
  state: errorStoreState,
  actions: errorStoreActions,
})
