import i18n from '@/plugins/i18n/index'

export const paginationPage = [
  { value: 10, title: '10' },
  { value: 25, title: '25' },
  { value: 50, title: '50' },
  { value: 100, title: '100' },
]

export const paginationPageGlobalSearch = [
  { value: 5, title: '5' },
  { value: 10, title: '10' },
  { value: 25, title: '25' },
  { value: 50, title: '50' },
  { value: 100, title: '100' },
]

export const mastersRoutes = {
  clusters: 'masters-clusters',
  routes: 'masters-routes',
  remarks: 'masters-remarks',
  documentType: 'masters-document-types',
  templates: 'masters-templates',
  documents: 'documents',
  mcBooks: 'masters-mc-book',
  conditions: 'masters-conditions',
  smsHeaders: 'masters-sms-header',
  bank: 'masters-bank',
}

export const headers = [
  { title: i18n.global.t('ID'), key: 'id' },
  { title: i18n.global.t('Name'), key: 'name', width: 1000 },
  { title: i18n.global.t('Created Time'), key: 'created_time' },
  { title: i18n.global.t('Updated Time'), key: 'updated_time' },
  { title: i18n.global.t('Action'), key: 'action', align: 'center', width: 120 },
]

export const mastersBulkOptions = [
  i18n.global.t('Active'),
  i18n.global.t('Inactive'),

  // i18n.global.t('Delete') => commented as per client requirement
]

export const loadOfferStatus = [
  { text: i18n.global.t('Available'), value: 'Available' },
  { text: i18n.global.t('Expiring'), value: 'Expiring' },
  { text: i18n.global.t('In process'), value: 'In process' },
  { text: i18n.global.t('Expired'), value: 'Expired' },
  { text: i18n.global.t('Cancelled'), value: 'Cancelled' },
  { text: i18n.global.t('All'), value: '' },
]

export const loadOfferSuggestionStatus = [
  { text: i18n.global.t('Available'), value: 'Available' },
  { text: i18n.global.t('Expiring'), value: 'Expiring' },
  { text: i18n.global.t('All'), value: '' },
]

export const LoadBoardSearch = reactive({
  route_place_name: null,
  industry_load_material_industry: null,
  id: '',
  loader_id: '',
  business_area: '',
  business_name: '',
  load_material_industry: null,
  conditions: null,
  is_public: null,
  remark: null,
  cluster_place_name: '',
  trading_name: '',
  primary_fullname: '',
  preferred_contact: '',
  alternative_fullname: '',
  alternative_mobiles: '',
  loading_mobiles: '',
  unloading_mobiles: '',
  loading_place_name: '',
  unloading_place_name: '',
  freight_method: '',
  capacity: '',
  loading_date: null,
  status: '',
  lockers_full_name: '',
  is_lock: '',
})

export const placeNameStatus = [i18n.global.t('Active'), i18n.global.t('Inactive')]

export const docStatus = [i18n.global.t('Approve'), i18n.global.t('Reject'), i18n.global.t('Delete')]

export const documentsStatus = [{ text: i18n.global.t('Approved'), value: 'Approved' }, { text: i18n.global.t('Rejected'), value: 'Rejected' }, { text: i18n.global.t('Expiring'), value: 'Expiring' }, { text: i18n.global.t('Expired'), value: 'Expired' }, { text: i18n.global.t('Pending'), value: 'Pending' }, { text: i18n.global.t('All'), value: '' }]

export const docArchiveStatus = [i18n.global.t('Delete')]

export const docType = {
  approve: 'approve',
  reject: 'reject',
  delete: 'delete',
}

export const docBulkStatus = {
  approve: 'Approve',
  reject: 'Reject',
  delete: 'Delete',
}

export const docStatusType = {
  approve: 'approve',
  reject: 'decline',
  delete: 'delete',
}

export const statusType = [
  { text: i18n.global.t('Active'), value: '1' },
  { text: i18n.global.t('Inactive'), value: '0' },
  { text: i18n.global.t('All'), value: '' },
]

export const isActive = [
  { id: true, name: i18n.global.t('Active') },
  { id: false, name: i18n.global.t('Expired') },
]

export const archivedType = [{ text: i18n.global.t('Active'), value: '1' },
  { text: i18n.global.t('Archived'), value: 'archived' },
  { text: i18n.global.t('All'), value: '' }]

export const DocumentTypes = [
  { text: i18n.global.t('loader.type'), value: 'loader' },
  { text: i18n.global.t('trucker.type'), value: 'trucker' },
  { text: i18n.global.t('Employee'), value: 'employee' },
  { text: i18n.global.t('Driver'), value: 'driver' },
  { text: i18n.global.t('Branch'), value: 'branch' },
  { text: i18n.global.t('Truck'), value: 'trucks' },
  { text: i18n.global.t('Bank'), value: 'bank' },
  { text: i18n.global.t('Confirmation'), value: 'confirmation' },
  { text: i18n.global.t('POD'), value: 'pod' },
  { text: i18n.global.t('ALL'), value: '' },
]

export const masterDocumentTypes = [
  { text: i18n.global.t('loader.type'), value: 'loader' },
  { text: i18n.global.t('trucker.type'), value: 'trucker' },
  { text: i18n.global.t('Employee'), value: 'employee' },
  { text: i18n.global.t('Driver'), value: 'driver' },
  { text: i18n.global.t('Branch'), value: 'branch' },
  { text: i18n.global.t('Truck'), value: 'truck' },
  { text: i18n.global.t('Bank'), value: 'bank' },
  { text: i18n.global.t('Confirmation'), value: 'confirmation' },
  { text: i18n.global.t('POD'), value: 'pod' },
  { text: i18n.global.t('ALL'), value: '' },
]

export const DocumentAddTypes = [
  { text: i18n.global.t('loader.type'), value: 'loader' },
  { text: i18n.global.t('trucker.type'), value: 'trucker' },
  { text: i18n.global.t('Employee'), value: 'employee' },
  { text: i18n.global.t('Driver'), value: 'driver' },
  { text: i18n.global.t('Branch'), value: 'branch' },
  { text: i18n.global.t('Truck'), value: 'truck' },
  { text: i18n.global.t('Bank'), value: 'bank' },
  { text: i18n.global.t('Confirmation'), value: 'confirmation' },
  { text: i18n.global.t('POD'), value: 'pod' },
  { text: i18n.global.t('ALL'), value: 'all' },
]
export const DocumentEditTypes = [
  { text: i18n.global.t('loader.type'), value: 'loader' },
  { text: i18n.global.t('trucker.type'), value: 'trucker' },
  { text: i18n.global.t('Employee'), value: 'employee' },
  { text: i18n.global.t('Driver'), value: 'driver' },
  { text: i18n.global.t('Branch'), value: 'branch' },
  { text: i18n.global.t('Truck'), value: 'truck' },
  { text: i18n.global.t('Bank'), value: 'bank' },
  { text: i18n.global.t('Confirmation'), value: 'confirmation' },
  { text: i18n.global.t('POD'), value: 'pod' },
]

export const ConfirmationSearchTypes = [
  { text: i18n.global.t('loader.type'), value: 'loader' },
  { text: i18n.global.t('trucker.type'), value: 'trucker' },
  { text: i18n.global.t('Confirmation'), value: 'confirmation' },
  { text: i18n.global.t('ALL'), value: '' },
]

export const ConfirmationTypes = [
  { text: i18n.global.t('loader.type'), value: 'loader' },
  { text: i18n.global.t('trucker.type'), value: 'trucker' },
  { text: i18n.global.t('Confirmation'), value: 'confirmation' },
  { text: i18n.global.t('ALL'), value: 'all' },
]

export const ConfirmationEditTypes = [
  { text: i18n.global.t('loader.type'), value: 'loader' },
  { text: i18n.global.t('trucker.type'), value: 'trucker' },
  { text: i18n.global.t('Confirmation'), value: 'confirmation' },
]

export const masterActive = [
  { text: i18n.global.t('Active'), value: 1 },
  { text: i18n.global.t('Inactive'), value: 0 },
]

export const IsAction = [{ text: i18n.global.t('Yes'), value: '1' }, { text: i18n.global.t('No'), value: '0' }, { text: i18n.global.t('All'), value: '' }]

export const TemplatesType = [
  { text: i18n.global.t('SMS'), value: 1 },
  { text: i18n.global.t('WhatsApp'), value: 2 },
]

export const messageRecordSection = [1, 2, 3, 4, 5]

export const messageTemplateType = [
  { text: i18n.global.t('SMS'), value: 1 },
  { text: i18n.global.t('WhatsApp'), value: 2 },
  { text: i18n.global.t('All'), value: '' },
]

export const Provider = [
  { text: i18n.global.t('MSG91'), value: 112 },
  { text: i18n.global.t('India Bulk SMS'), value: 'WhatsApp' },
  { text: i18n.global.t('Bulk SMS Hyderabad'), value: '' },
]

export const category = [
  { text: i18n.global.t('Static'), value: 1 },
  { text: i18n.global.t('Variable'), value: 2 },
]

export const searchCategory = [
  { text: i18n.global.t('All'), value: '' },
  { text: i18n.global.t('Static'), value: 1 },
  { text: i18n.global.t('Variable'), value: 2 },
  { text: i18n.global.t('master.system_generated'), value: 3 },
]

export const operatorRoles = [i18n.global.t('Load Operator'), i18n.global.t('Truck Operator'), i18n.global.t('Accounts Operator')]

export const subCategory = [
  { text: i18n.global.t('loader.type'), value: 1 },
  { text: i18n.global.t('trucker.type'), value: 2 },
  { text: i18n.global.t('Offers'), value: 3 },
  { text: i18n.global.t('Loads'), value: 4 },
  { text: i18n.global.t('Confirmation'), value: 5 },
]

export const searchSubCategory = [
  { text: i18n.global.t('loader.type'), value: 1 },
  { text: i18n.global.t('trucker.type'), value: 2 },
  { text: i18n.global.t('Offers'), value: 3 },
  { text: i18n.global.t('Loads'), value: 4 },
  { text: i18n.global.t('Confirmation'), value: 5 },
  { text: i18n.global.t('All'), value: '' },
]

export const earningReports = [
  {
    title: i18n.global.t('Email'),
    input: 'Admin@test.com',
  },
  {
    title: i18n.global.t('Phone'),
    input: '+919820000000',
  },
  {
    title: 'Password:',
  },
]

export const mastersData = reactive(
  {
    id: 0,
    name: '',
    is_active: 1,
    code: '',
    type: null,
    description: '',
    is_public: 0,
    has_expiry: 0,
    is_primary: 0,
    display_name: '',
    content: '',
    place_id: '',
    place_name: '',
    place_latitude: '',
    place_longitude: '',
    place_reference: '',
  })

export const masterTableOptions = reactive({
  page: 1,
  results: 10,
  sortField: '',
  sortOrder: '',
  search: false,
})

export const statusUpdateData = reactive(
  {
    ids: [],
    is_active: 0,
  })

export const bulkStatus = {
  active: 'Active',
  inActive: 'InActive',
  inactive: 'Inactive',
  delete: 'Delete',
}

export const showStatus = {
  inactive: 'inactive',
  active: 'active',
}

export const mastersModule = {
  mcBooks: 'mc-books',
  banks: 'banks',
}

export const workOptions = [
  { text: i18n.global.t('loader.type'), value: 'loader' },
  { text: i18n.global.t('trucker.type'), value: 'trucker' },
  { text: i18n.global.t('Individual'), value: 'individual' },
]

export const acceptedBannerFiles = ['.jpg', '.jpeg', '.png']

export const workableTypes = {
  loader: i18n.global.t('users.loader'),
  trucker: i18n.global.t('users.trucker'),
  individual: i18n.global.t('Individual'),
}

export const usersTypes = [
  { text: i18n.global.t('loader.type'), value: 'loader' },
  { text: i18n.global.t('trucker.type'), value: 'trucker' },
]

export const offerBoardSearch = reactive({
  loading_date: null,
  conditions: null,
  cluster_place_name: null,
  preferred_materials: null,
  status: '',
  remark: null,
  is_public: null,
  id: '',
  trucker_id: '',
  business_name: '',
  business_area: '',
  primary_fullname: '',
  primary_preferred_contact: '',
  alternative_fullname: '',
  alternative_mobiles: '',
  driver_fullname: '',
  driver_preferred_contact: '',
  truck_number: '',
  tyres: '',
  routes: '',
  capacity: null,
  place_name: null,
})
