import { resolveVuetifyTheme } from '@core/utils/vuetify'
import type { VuetifyOptions } from 'vuetify'

export const staticPrimaryColor = '#f45e3d'

const theme: VuetifyOptions['theme'] = {
  defaultTheme: resolveVuetifyTheme(),
  themes: {
    light: {
      dark: false,
      colors: {
        'primary': '#f45e3d',
        'on-primary': '#fff',
        'base': '#FDE5E0',
        'main': '#fef2c5',
        'secondary': '#A8AAAE',
        'on-secondary': '#fff',
        'success': '#28C76F',
        'on-success': '#fff',
        'info': '#00CFE8',
        'on-info': '#fff',
        'warning': '#FF9F43',
        'on-warning': '#fff',
        'error': '#EA5455',
        'dark': '#001529',
        'background': '#F8F7FA',
        'on-background': '#2F2B3D',
        'on-surface': '#2F2B3D',
        'blue': '#0052CC',
        'purple': '#7367F0',
        'grey-50': '#FAFAFA',
        'grey-100': '#F5F5F5',
        'grey-200': '#EEEEEE',
        'grey-300': '#E0E0E0',
        'grey-400': '#BDBDBD',
        'grey-500': '#9E9E9E',
        'grey-600': '#757575',
        'grey-700': '#616161',
        'grey-800': '#424242',
        'grey-900': '#212121',
        'perfect-scrollbar-thumb': '#DBDADE',
        'skin-bordered-background': '#fff',
        'skin-bordered-surface': '#fff',
        'process-light': '#929292',
        'process-dark': '#5B7C99',
        'process-red': '#FD5C3F',
        'process-blue': '#006CE5',
        'process-yellow': '#FBD739',
        'process-green': '#72BB53',
        'action': '#FBD73A',
        'lock': '#e9ecef',
      },

      variables: {
        'code-color': '#d400ff',
        'overlay-scrim-background': '#4C4E64',
        'tooltip-background': '#4A5072',
        'overlay-scrim-opacity': 0.5,
        'hover-opacity': 0.04,
        'focus-opacity': 0.12,
        'selected-opacity': 0.06,
        'activated-opacity': 0.16,
        'pressed-opacity': 0.14,
        'dragged-opacity': 0.1,
        'disabled-opacity': 0.42,
        'border-color': '#2F2B3D',
        'border-opacity': 0.16,
        'high-emphasis-opacity': 0.78,
        'medium-emphasis-opacity': 0.68,
        'switch-opacity': 0.2,
        'switch-disabled-track-opacity': 0.3,
        'switch-disabled-thumb-opacity': 0.4,
        'switch-checked-disabled-opacity': 0.3,

        // Shadows
        'shadow-key-umbra-color': '#2F2B3D',
      },
    },
  },
}

export default theme
