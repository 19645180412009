import i18n from '@/plugins/i18n/index'

interface DashboardLatestDataHeader {
  title: string
  key: string
  sortable: boolean
  width: string
}

interface StatisticsData {
  title: string
  icon: string
  key: string
  shouldFormat: boolean
}

// tabler-box-seam
export const statisticsData: StatisticsData[] = [
  {
    title: i18n.global.t('Total Loaders'),
    icon: 'tabler-square-rounded-check',
    key: 'totalLoaders',
    shouldFormat: false,
  },
  {
    title: i18n.global.t('Total Truckers'),
    icon: 'tabler-truck',
    key: 'totalTruckers',
    shouldFormat: false,
  },
  {
    title: i18n.global.t('Total Trucks'),
    icon: 'tabler-truck',
    key: 'totalTrucks',
    shouldFormat: false,
  },
  {
    title: i18n.global.t('Total MC Books'),
    icon: 'tabler-user-circle',
    key: 'totalMcBooks',
    shouldFormat: false,
  },
  {
    title: i18n.global.t('Total Confirmations'),
    icon: 'tabler-bookmark',
    key: 'totalConfirmation',
    shouldFormat: false,
  },
  {
    title: i18n.global.t('Total MC Books Receivable'),
    icon: 'tabler-currency-rupee',
    key: 'totalMcBooksReceivable',
    shouldFormat: true,
  },
  {
    title: i18n.global.t('Total Receivable'),
    icon: 'tabler-currency-rupee',
    key: 'totalReceivable',
    shouldFormat: true,
  },
  {
    title: i18n.global.t('Total Payable'),
    icon: 'tabler-currency-rupee',
    key: 'totalPayable',
    shouldFormat: true,
  },
]

export const ledgerBalance = [
  {
    title: i18n.global.t('Total Receivable'),
    key: 'totalReceivable',
  },
  {
    title: i18n.global.t('Total Received'),
    key: 'totalReceived',
  },
  {
    title: i18n.global.t('Total Payable'),
    key: 'totalPayable',
  },
  {
    title: i18n.global.t('Total Paid'),
    key: 'totalPaid',
  },
  {
    title: i18n.global.t('Balance'),
    key: 'balance',
    color: true,
  },

]

export const dashboardLatestDataHeader: DashboardLatestDataHeader[] = [
  { title: 'Loader Business Name', key: 'loaderBusinessName', sortable: false, width: '5%' },
  { title: 'Loading Point', key: 'loadingPoints', sortable: false, width: '15%' },
  { title: 'Unloading Point', key: 'unloadingPoints', sortable: false, width: '10%' },
  { title: 'Capacity', key: 'capacity', sortable: false, width: '10%' },
  { title: 'Trucker Business Name', key: 'truckerBusinessName', sortable: false, width: '15%' },
  { title: 'Truck No.', key: 'truckNumber', sortable: false, width: '10%' },
  { title: 'FR + PR + OR', key: 'freight', sortable: false, width: '10%' },
  { title: 'Adv. + To Pay + POD', key: 'amount', sortable: false, width: '10%' },
  { title: 'Timestamp', key: 'timestamp', sortable: false, width: '10%' },
  { title: 'Actions', key: 'actions', sortable: false, width: '5%' },
]
