import { defineStore } from 'pinia'
import { useErrorStore } from './error'
import { documentsTypesOptions, masterTableOptions, mastersData, statusUpdateData, successMessage } from '@/constants'
import { MasterService } from '@/services/apis'
import { useToasterStore } from '@/stores/toaster'

const { getMastersData, storeMastersData, addMastersData, getSmsHeader, getSmsHeaderDetails, getUsers, updateSmsHeaderStatus, getTemplateById, updateTemplateData, createTemplate, getSmsTemplateInfo, AddMastersData, editMastersData, deleteMastersMcData, deleteMastersData, updateMastersData, getMastersCondition, updateMastersStatus, getMastersById, getMastersCommonData, getUsersBusinessNames } = MasterService()
const { state: toasterStoreState } = useToasterStore()
const { actions: errorStoreActions } = useErrorStore()

export const useMastersStore = defineStore('masters', {
  state: () => ({
    isSnackbarVisible: false,
    isDeleteDialogVisible: false,
    toastMsg: '',
    totalPage: '',
    list: [],
    isActive: '',
    perPage: '',
    CurrentPage: '',
    getPlaceName: '',
    getUsersName: [],
    getWorkableId: '',
    getRoutes: [],
    getClusters: [],
    getMaterials: [],
    getIndustries: [],
    getRemark: [],
    getMastersConditions: [],
    isLoading: false,
    getUserInfo: [],
    templateData: [],
    smsHeaderList: [],
    templateFlowID: [],
    smsHeaderData: [],
    confirmationDocumentsTypes: [],
    loaderDocumentsTypes: [],
    truckerDocumentsTypes: [],
    podDocumentsTypes: [],
    isHeaderLoading: false,
    loading: false,
    listData: [],
    businessNameDetails: [],
    businessNamesLoading: false,
    nextPageCursorId: null,
    hasMore: true,
    loadAllBusinessNames: false,
    mastersDetails: [],
  }),
  actions: {
    async getMasterDataStore(mastersType: ScrollSetting | any) {
      this.isLoading = true

      const response = await getMastersData(masterTableOptions, mastersType)
      if (response?.data) {
        const { total, per_page, current_page, data, data: { is_active } } = response.data

        this.list = data
        this.totalPage = total
        this.isActive = is_active
        this.perPage = per_page
        this.CurrentPage = current_page
      }
      this.isLoading = false
    },

    async getMastersNodeData(mastersType: ScrollSetting | any, tableOptions: object) {
      this.loading = true

      const response = await getMastersData(tableOptions, mastersType)

      if (response?.data) {
        this.listData = response.data?.data?.results
        this.totalPage = response.data.data.totalCount
      }
      this.loading = false
    },

    async getSmsHeaderData(headerOptions: object) {
      this.isLoading = true

      const response = await getSmsHeaderDetails(headerOptions)

      if (response?.data) {
        const { totalSmsHeaders } = response.data.data

        this.smsHeaderList = response.data?.data?.allSmsHeaders
        this.totalPage = totalSmsHeaders
      }
      this.isLoading = false
    },

    async getSmsHeaderStore(headerOptions: object) {
      this.isHeaderLoading = true

      const response = await getSmsHeader(headerOptions)

      if (response?.data)
        this.smsHeaderData = response.data?.data?.allSmsHeaders

      this.isHeaderLoading = false
    },

    async insertMasterDataStore(mastersType: string) {
      try {
        const addUsersData = await storeMastersData(mastersData, mastersType)

        if (addUsersData) {
          if (addUsersData?.data?.message)
            await toasterStoreState.displaySnackbar(addUsersData?.data?.message?.replace('created', 'added'), 'success')

          else
            await toasterStoreState.displaySnackbar(successMessage.masters.createSmsHeader, 'success')

          errorStoreActions.clearErrors()

          return true
        }

        return false
      }
      catch (error) {
        // await toasterStoreState.displaySnackbar(error, 'error')
        this.isSnackbarVisible = true
      }
    },

    // Message Template listing API
    async getSmsTemplateDetails(smsParams: object) {
      this.isLoading = true

      const response = await getSmsTemplateInfo(smsParams)

      if (response?.data?.data) {
        const { totalRecords, per_page, current_page } = response.data.data

        const templateDetails = Object.values(response.data.data).map((template: any) => {
          return template
        })

        this.templateData = templateDetails?.at(0)
        this.totalPage = totalRecords
        this.perPage = per_page
        this.CurrentPage = current_page
      }
      this.isLoading = false
    },

    async getMasterByIdStore(id: string | number, mastersType: string) {
      const getId = await getMastersById(id, mastersType)

      if (getId && getId.data) {
        this.mastersDetails = getId.data
        this.getPlaceName = getId.data.place_name
        Object.assign(mastersData, getId.data)
      }
    },

    // Template View
    async getTemplateByIdStore(id: number, templateInfo: object) {
      const getId = await getTemplateById(id)

      if (getId && getId.data.data) {
        this.mastersDetails = getId.data.data

        templateInfo.senderId = getId?.data?.data?.provider?.at(0)?.senderId
        Object.assign(templateInfo, getId.data.data)
        this.templateFlowID = getId.data.data
      }
    },

    async deleteMastersStore(ids: [], mastersType: string) {
      try {
        const deleteMaterial = await deleteMastersData(ids, mastersType)

        this.isDeleteDialogVisible = false

        if ([200, 201].includes(deleteMaterial.status))

          await toasterStoreState.displaySnackbar(deleteMaterial.data.message, 'success')
      }
      catch (error) {

      }
    },

    // Template create API
    async insertTemplateData(templateData: object) {
      try {
        const response = await createTemplate(templateData)

        if (response) {
          await toasterStoreState.displaySnackbar(successMessage.masters.addMessageTemplate, 'success')
          errorStoreActions.clearErrors()

          return true
        }

        return false
      }
      catch (error) {
        this.isSnackbarVisible = true
      }
    },

    async updateMastersStatusStore(mastersType: string) {
      try {
        const status = await updateMastersStatus(statusUpdateData, mastersType)

        if ([200, 201].includes(status.status))
          await toasterStoreState.displaySnackbar(status.data.message, 'success')
      }
      catch (error) {
        this.isSnackbarVisible = true
      }
    },

    // Template Status Update
    async updateSmsHeaderStore(statusData: object) {
      try {
        const status = await updateSmsHeaderStatus(statusData)

        if ([200, 201].includes(status.status))
          await toasterStoreState.displaySnackbar(status.data.data.message, 'success')
      }
      catch (error) {
        this.isSnackbarVisible = true
      }
    },

    async updateMastersDataStore(id: number, mastersType: string) {
      try {
        const response = await updateMastersData(id, mastersData, mastersType)
        if (response) {
          await toasterStoreState.displaySnackbar(response.data.message, 'success')

          errorStoreActions.clearErrors()

          return true
        }

        return false
      }
      catch (error) {
        this.isSnackbarVisible = true
      }
    },

    // update Template data
    async updateTemplateDataStore(id: number, templateInfo: object) {
      try {
        const response = await updateTemplateData(id, templateInfo)
        if (response) {
          await toasterStoreState.displaySnackbar(successMessage.masters.updateMessageTemplate, 'success')

          errorStoreActions.clearErrors()

          return true
        }

        return false
      }
      catch (error) {
        this.isSnackbarVisible = true
      }
    },

    async getAllMastersData(mastersType: string) {
      const response = await getMastersCommonData(mastersType)

      if (response?.data.data) {
        const { routes, clusters, materials, industries, remark } = response?.data.data || {}

        this.getRoutes = routes
        this.getClusters = clusters
        this.getMaterials = materials
        this.getIndustries = industries
        this.getRemark = remark
      }
    },

    async getConditionData(mastersType: string, type: string) {
      const response = await getMastersCondition(mastersType, type)
      if (response?.data.data) {
        const { conditions } = response?.data.data || {}

        this.getMastersConditions = conditions
      }
    },

    // here reactive data on vue side
    async addMasterDataStore(mastersType: string, mastersInfo: object) {
      try {
        const addUsersData = await addMastersData(mastersType, mastersInfo)
        if (addUsersData.data) {
          await toasterStoreState.displaySnackbar(addUsersData.data.message, 'success')
          errorStoreActions.clearErrors()

          return true
        }

        return false
      }
      catch (error) {
        this.isSnackbarVisible = true
      }
    },

    async getMasterMcId(id: number, mastersType: string, data: object) {
      const getId = await getMastersById(id, mastersType)

      if (getId && getId.data)
        data.fullname = getId?.data?.owner?.user?.fullname
      Object.assign(data, getId.data)
    },

    async getPrimaryName(data: object) {
      const response = await getUsers(data)
      if (response?.data)
        this.getUserInfo = response?.data

      const getFullName = response?.data.map((item: object) => item.fullname)

      const getId = response?.data.map((item: object) => item.id)

      this.getUsersName = getFullName
      this.getWorkableId = getId
    },

    async getBusinessNameByType(userType: string, searchValue?: string | null, isLoadMoreItem = false) {
      this.businessNamesLoading = true

      if (searchValue && !isLoadMoreItem) {
        this.businessNameDetails = []
        this.nextPageCursorId = null
      }

      // checking if we want to load all data passing limit null
      const response = await getUsersBusinessNames(userType, {
        limit: this.loadAllBusinessNames ? null : 10,
        cursorId: this.nextPageCursorId,
        searchTerm: searchValue ?? null,
      })

      if (response?.data?.data?.results) {
        this.nextPageCursorId = response?.data?.data?.nextPageCursorId
        this.hasMore = this.nextPageCursorId !== 0

        const newBusinessNameDetails = response?.data?.data?.results?.map(item => {
          return {
            ...item,
            title: `${item?.id} - ${item?.businessName}`,
            subTitle: `${item?.id} - ${item?.businessName} - ${item?.preferredContact}`,
          }
        })

        if (isLoadMoreItem && this.hasMore)
          this.businessNameDetails = [...this.businessNameDetails, ...newBusinessNameDetails]
        else
          this.businessNameDetails = [...newBusinessNameDetails]

        this.businessNamesLoading = false
      }

      this.businessNamesLoading = false
    },

    // here reactive data on vue side
    async updateMasterDataStore(id: number, mastersType: string, mastersInfo: object) {
      try {
        const response = await updateMastersData(id, mastersInfo, mastersType)
        if (response) {
          await toasterStoreState.displaySnackbar(response.data.message, 'success')
          errorStoreActions.clearErrors()

          return true
        }

        return false
      }
      catch (error) {
        this.isSnackbarVisible = true
      }
    },

    async deleteMastersMcStore(mastersType: string, data: object) {
      try {
        const deleteMaterial = await deleteMastersMcData(mastersType, data)

        await toasterStoreState.displaySnackbar(deleteMaterial.data.message, 'success')
      }
      catch (error) {

      }
    },

    // banner view API
    async getMasterIdStore(id: string, mastersType: string, data: object) {
      const getId = await getMastersById(id, mastersType)

      if (getId && getId.data) {
        this.getPlaceName = getId.data.place_name
        Object.assign(data, getId.data)
      }
    },

    // banner Add API
    async insertMasterStore(mastersType: string, data: object) {
      try {
        const addUsersData = await AddMastersData(data, mastersType)

        if (addUsersData) {
          await toasterStoreState.displaySnackbar(addUsersData?.data?.message?.replace('created', 'added'), 'success')
          errorStoreActions.clearErrors()

          return true
        }

        return false
      }
      catch (error) {
        this.isSnackbarVisible = true
      }
    },

    // banner Edit API
    async updateMastersStore(id: number, mastersType: string, data: object) {
      try {
        const response = await editMastersData(id, data, mastersType)
        if (response) {
          await toasterStoreState.displaySnackbar(response.data.message, 'success')

          errorStoreActions.clearErrors()

          return true
        }

        return false
      }
      catch (error) {
        this.isSnackbarVisible = true
      }
    },

    async getConfirmationDocumentTypes() {
      this.isLoading = true
      documentsTypesOptions.search = true
      documentsTypesOptions.type = 'confirmation'

      const response = await getMastersData(documentsTypesOptions, 'document-types')
      if (response?.data) {
        const { total, per_page, current_page, data, data: { is_active } } = response.data

        this.confirmationDocumentsTypes = data
        this.totalPage = total
        this.isActive = is_active
        this.perPage = per_page
        this.CurrentPage = current_page
      }
      this.isLoading = false
    },

    async getLoaderDocumentTypes() {
      this.isLoading = true
      documentsTypesOptions.search = true
      documentsTypesOptions.type = 'loader'
      documentsTypesOptions.is_active = 1
      documentsTypesOptions.page = 1
      documentsTypesOptions.results = 100

      const response = await getMastersData(documentsTypesOptions, 'document-types')
      if (response?.data) {
        const { total, per_page, current_page, data, data: { is_active } } = response.data

        this.loaderDocumentsTypes = data
        this.totalPage = total
        this.isActive = is_active
        this.perPage = per_page
        this.CurrentPage = current_page
      }
      this.isLoading = false
    },

    async getTruckerDocumentTypes() {
      this.isLoading = true
      documentsTypesOptions.search = true
      documentsTypesOptions.type = 'trucker'

      const response = await getMastersData(documentsTypesOptions, 'document-types')
      if (response?.data) {
        const { total, per_page, current_page, data, data: { is_active } } = response.data

        this.truckerDocumentsTypes = data
        this.totalPage = total
        this.isActive = is_active
        this.perPage = per_page
        this.CurrentPage = current_page
      }
      this.isLoading = false
    },

    async getPodDocumentsTypes() {
      this.isLoading = true
      documentsTypesOptions.search = true
      documentsTypesOptions.type = 'pod'

      const response = await getMastersData(documentsTypesOptions, 'document-types')
      if (response?.data) {
        const { total, per_page, current_page, data, data: { is_active } } = response.data

        this.podDocumentsTypes = data
        this.totalPage = total
        this.isActive = is_active
        this.perPage = per_page
        this.CurrentPage = current_page
      }
      this.isLoading = false
    },
  },
})
